import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent,
  SpaceBlock
} from './styles'
import Img from 'gatsby-image'

import RCM from '../../../assets/images/RCM.png'
import RBE from '../../../assets/images/RBE.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import RentlioIntegracije from '../../../assets/images/HMSintegrations.png'
import RentlioKalendar from '../../../assets/images/Kalendar.png'
import Housekeeping from '../../../assets/images/Housekeeping.png'
import Statistika from '../../../assets/images/Statistika.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'Ključne funkcionalnosti hotelskog sutava'
  },
  {
    id: 2,
    title: 'Iskoristite sve značajke i funkcionalnosti hotelskog sustava'
  },
  {
    id: 3,
    title: 'Odabir hotelskog sustava – Troškovi, implementacija, onboarding i korisnička podrška'
  },
  {
    id: 4,
    title: 'Zašto Rentlio?'
  }
]

const Content = ({}) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/property-management-system'
  }
  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>Teme unutar stranice</TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <H2 id="1" className="sections">
          Ključne funkcionalnosti hotelskog sustava
        </H2>
        <H3>Property management sustav</H3>
        <P>
          <a href="https://rentl.io/hotel-property-management-system/">Property management sustav (PMS)</a> srce je
          poslovanja svakog hotela. Trenutno su hotelijerima na raspolaganju dvije vrste hotel-tech rješenja i hotelskih
          softvera: on-premise i cloud-based property management sustavi. On-premise PMS softveri razvijeni su prije
          više od dvadeset godina i namijenjeni su upravljanju uglavnom jednostavnim recepcijskim zadacima poput
          check-in i check-out procesa. S vremenom je on-premise softver djelomično evoluirao, ali neke od
          karakteristika, poput vremena potrebnog za implementaciju, visokih troškova održavanja, složene prilagodbe i
          zastarjelih sučelja još uvijek ne prate moderne trendove i potrebe u hotelijerstvu i turizmu.
        </P>
        <P>
          <a href="https://rentl.io/property-management-system">Cloud-based PMS softver</a> je, s druge strane,
          osmišljen kako bi pojednostavio poslovne procese front deska, automatizirao svakodnevne ponavljajuće zadatke,
          reducirao nepotrebne troškove i poboljšao efikasnost poslovanja. Uz moderno i intuitivno sučelje, brzu
          implementaciju, besplatne i automatske nadogradnje, cloud PMS danas je prvi izbor većine hotelijera.
        </P>
        <P>
          <a href="https://rentl.io/hotel">Saznajte više o ključnim značajkama modernog cloud-based PMS sustava.</a>
        </P>

        <H3>Channel manager</H3>
        <P>
          Hotel channel manager sinkronizira cijene i ažurira dostupnost na svim OTA kanalima, poput Booking.com-a,
          Expedia-e i drugima. Danas je dostupno mnogo channel managera koji nude integraciju s vašim property
          management sustavom. Takva su rješenja dodatan trošak već visokim provizijama prodajnih OTA kanala. Rentlio
          nudi unaprijed integriran PMS sustav i Channel Manager, koji su uvijek sinkronizirani i povezani. Cijene i
          zauzeće ažuriraju se izravno u property management sustavu, a izmjene su vidljive na svim prodajnim kanalima u
          roku od nekoliko sekundi. Integrirana rješenja značajno štede vrijeme i smanjuju mogućnost pogrešaka.
        </P>
        <P>
          Uz integriran hotel PMS i channel manager, Rentlio možete povezati s više OTA rezervacijskih kanala i
          eliminirati overbooking. Sve direktne rezervacije i rezervacije koje stignu s bilo kojeg OTA kanala uvijek su
          sinkronizirane i automatski vidljive unutar Rentlio PMS-a.
        </P>
        <P>
          <a href="https://rentl.io/property-management-system">
            Saznajte više o Rentlijevom integriranom PMS-u i channel manageru.
          </a>
        </P>
        <FullWidthImg style={{ width: '60%' }}>
          <img src={RCM} />
        </FullWidthImg>
        <ImgTitle>Rentlio Channel Manager</ImgTitle>
        <H3>Web Booking Engine</H3>
        <P>
          Web Booking Engine je widget postavljen na web stranicu hotela kako biste svojim gostima omogućili direktne
          rezervacije. Zbog visokih provizija mnogi hotelijeri traže načine za povećanje prihoda uklanjanjem nepotrebnih
          troškova. Baš kao i u slučaju hotelskog channel managera, u industriji i hotelijerstvu mnogo je pružatelja
          booking engine usluga. Osim cijene booking engine-a budite sigurni da se može integrirati s vašim PMS-om,
          inače nećete moći primati izravne rezervacije.
        </P>
        <P>
          Rentlijev integrirani Web Booking Engine potpuno je povezan s PMS-om i sinkronizirat će sve rezervacije na
          svim kanalima. Widget i Booking Engine prilagodite u skladu s dizajnom svoje web stranice i primajte izravne
          rezervacije direktno u svoj hotelski PMS.
        </P>
        <P>
          <a href="https://rentl.io/booking-engine">
            Otkrijte više o Rentlio integriranom Property Management sustavu i Web Booking Engine-u.
          </a>
        </P>
        <FullWidthImg>
          <img src={RBE} />
        </FullWidthImg>
        <ImgTitle>Rentlio Booking Engine</ImgTitle>
        <H3>Mobilna aplikacija</H3>
        <P>
          Mobilna aplikacija vrlo je važna kako biste digitalno transformirali svoje hotelsko poslovanje. Aplikacija
          omogućuje voditeljima i managerima hotela pristup poslovanju kako bi stalno imali kontrolu i uvijek bili u
          tijeku sa svime što se događa u hotelu. Mobilnom aplikacijom manageri i voditelji hotela lako mogu provjeriti
          status bookinga, rezervacija, i unijeti promjene prema vlastitim željama. Ako koristite Rentlio hotel PMS,
          jednostavno preuzmite IOS ili Android Rentlio mobilnu aplikaciju, prijavite se, pregledajte trenutne
          rezervacije i ažurirajte promjene. Zbog intuitivnog dizajna, Rentlio mobilna aplikacija jednostavna je i laka
          za korištenje.
        </P>
        <P>
          <a href="https://rentl.io/mobilna-aplikacija">Saznajte više o Rentlio mobilnoj aplikaciji</a> i kako će vam
          pomoći poslovati efikasnije.
        </P>
        <FullWidthImg>
          <img src={MobileApps} />
        </FullWidthImg>
        <ImgTitle>Rentlio Mobilna aplikacija</ImgTitle>
        <H3>Integracije</H3>
        <P>
          Integracije su trenutno trend i buzzword u hotelijerstvu, i hotel-tech industriji generalno. Integracije su, u
          osnovi, aplikacije i rješenja koja se API komunikacijom povezuju s vašim hotelskim PMS-om. Više od 1000
          integracija dostupno je u industriji putovanja i hotelijerstvu, od integracija koje su fokusirane na upsell
          mogućnosti, revenue i rate management do channel managera i booking engine-a koji su i integracije, a
          spomenute su ranije.
        </P>
        <P>
          Svaki hotelijer procjenom vlastitog poslovanja odabire dodatne aplikacije i softvere kako bi složio cjelovit
          tech-stack prilagođen poslovanju. Svaka integracija istovremeno je dodatan trošak stoga je važno što vam je
          ponuđeno u paketu koji nudi vaš tehnološki partner i što je sve uključeno uz property management sustav. Jesu
          li booking engine i channel manager unaprijed integrirani? Ako je odgovor potvrdan, to će znatno smanjiti
          ukupne troškove hotelskog poslovanja.
        </P>
        <P>
          <a href="https://rentl.io/integracije">Otkrijte više o Rentlio integracijskim partnerima.</a>
        </P>
        <FullWidthImg>
          <img src={RentlioIntegracije} />
        </FullWidthImg>
        <ImgTitle>Rentlio Integracije</ImgTitle>
        <Form locale="hr" />
        <GreyBlock id="2" className="sections">
          <H2>Iskoristite sve značajke i funkcionalnosti hotelskog sustava</H2>
          <P>
            Hotelski sustav skup je značajki i funkcionalnosti kojima hotelijeri upravljaju operativnim dijelom
            hotelskog poslovanja. Svaki hotel je drugačiji; stoga se zahtjevi razlikuju u skladu s veličinom hotela i
            složenosti implementacije.
          </P>
          <P>U nastavku pronađite ključne značajke hotelskog sustava i utjecaj koji imaju na hotelske operacije.</P>
          <H3>Interaktivni kalendar</H3>
          <P>
            Ključ upravljanja bookingom i sinkronizacija dostupnosti svih OTA kanala. Interaktivni kalendar koristite
            kako biste pregledavali i upravljali svim rezervacijama i ažurirali cijene i dostupnost. Prednost
            integriranog PMS-a i channel managera je sinkronizacija svih podataka na svim OTA prodajnim kanalima bez
            overbookinga i preklapanja rezervacija.
          </P>
          <FullWidthImg>
            <img src={RentlioKalendar} />
          </FullWidthImg>
          <ImgTitle>Rentlio Kalendar</ImgTitle>
          <H3>Recepcija</H3>
          <P>
            Recepcija, mjesto prvog povezivanja gostiju i hotela. Kako bi ostavili dobar prvi dojam, recepcijsko
            osoblje, osim ljubaznosti i profesionalnosti, mora koristiti moderan i pouzdan hotelski softver. Određena
            iskustva već na recepciji utječu na dojam gosta. Popis rezervacija koji se lako pregledava, jednostavan
            check-in uz ID skenera, brz check-out s više opcija dijeljenja računa i još mnogo toga direktno ovisi o
            kvaliteti hotelskog softvera i dojmu koji ćete ostaviti na goste.
          </P>
          <H3>Naplata i plaćanja</H3>
          <P>
            Naplata je puno više od pukog ispisa računa. Hotelski softver omogućuje prilagodbu i brendiranje računa,
            dijeljenje računa temeljem želja vaših gostiju uz opciju isključivanja dodatnih usluga iz računa i još mnogo
            toga. Voditelji i manageri hotela moraju biti kreativni i gostu omogućiti više opcija plaćanja.
          </P>
          <H3>Houseekeeping</H3>
          <P>
            Sobe svoje goste trebaju dočekati čiste i spremne. Housekeeping funkcionalnost povezuje housekeeping osoblje
            i recepciju. Osim ažuriranja statusa sobe u stvarnom vremenu, dostupne su i opcije poput kreiranja rasporeda
            čišćenja, upravljanje rasporedima, pregleda statusa pojedinih soba te ažuriranje rezervacija temeljem tih
            statusa. Sve navedeno moguće je direktno iz središnjeg dashboarda Rentlija.
          </P>
          <FullWidthImg>
            <img src={Housekeeping} />
          </FullWidthImg>
          <ImgTitle>Rentlio Housekeeping</ImgTitle>
          <H3>Analitika i reporting</H3>
          <P>
            Iskoristite više od 50 izvješća. Rentlijevim reporting modulom analizirat ćete svoje hotelsko poslovanje i
            prodaju u potpunosti. Trebate li jednostavne podatke o popunjenosti ili podatke kako biste maksmalno
            predvidjeli prodaju i isplanirali nadolazeću godinu, Rentlio analitika omogućit će vam uvid u sve što vas
            zanima.
          </P>
          <FullWidthImg>
            <img src={Statistika} />
          </FullWidthImg>
          <ImgTitle>Rentlio Analitika</ImgTitle>
          <P>
            <a href="https://rentl.io/kontakt">Zatražite besplatne konzultacije već danas.</a>
          </P>
        </GreyBlock>
        <H2 id="3" className="sections">
          Odabir hotelskog sustava – Troškovi, implementacija, onboarding i korisnička podrška
        </H2>
        <H3>Troškovi</H3>
        <P>
          Trošak odabranog hotelskog sustava ovisi o više čimbenika poput broja jedinica, kompleksnosti poslovnih
          procesa, složenosti operacija i implementacije, broja integracija, razine korisničke podrške i slično.
        </P>
        <P>
          <a href="https://rentl.io/cijene">Izračunajte uštedu prelaskom na Rentlio.</a>
        </P>
        <H3>Implementacija</H3>
        <P>
          Prosječna implementacija Rentlija traje manje od 24 sata, što je neusporedivo brže od implementacije
          naslijeđenih on-premise hotelskih sustava, koja nerijetko traje mjesecima. Implementacija Rentlija je brza i
          besplatna, bez skrivenih naknada i troškova održavanja. Zbog besplatnih i automatskih nadogradnji, uvijek ćete
          imati dostupnu najnoviju verziju hotelskog PMS sustava.
        </P>
        <H3>Onboarding</H3>
        <P>
          Niste sami. Rentlijevi Onboarding specijalisti bit će uz vas tijekom cijelog postupka implementacije i
          pokazati vam kako koristiti svaku značajku i funkcionalnost našeg hotelskog softvera.
        </P>
        <H3>Korisnička podrška</H3>
        <P>
          Korisnička podrška ključ je uspjeha. Rentlio se ponosi svojim timom za korisničku podršku, koji je uvijek
          dostupan i rado će vam pomoći sa svim pitanjima koja imate. Prosječno vrijeme za odgovor naših Customer
          Support specijalista su 3:34 minute, pri čemu je 98,9% razgovora ocijenjeno najvišom ocjenom.
        </P>
        <FullWidthImg>
          <MiddleContent>
            <GradientText>99%</GradientText>
            <SideText>korisnika Rentlija ocjenjuje rad korisničke podrške s "Amazing"</SideText>
          </MiddleContent>
        </FullWidthImg>
        <H3>Ključno prilikom odabira hotelskog sustava</H3>
        <P>
          Cijena hotelskog sustava varira ovisno o broju jedinica, složenosti procesa i implementacije, integracijama i
          razini korisničke podrške.
          <br /> Uvijek odaberite cloud-native rješenja kako biste reducirali vrijeme implementacije i visoke troškove
          održavanja. <br />
          Saznajte koliko vremena je potrebno za implementaciju odabranog hotelskog softvera. <br />
          Saznajte jesu li besplatne i automatske nadogradnje hotelskog sustava uključene u ponudu. <br />
          Provjerite nudi li odabran tehnološki partner onboarding proces 1:1.
          <br /> Zatražite podatak o dostupnosti tima korisničke podrške. <br />
          Zatražite podatke o radu korisničke podrške, poput prosječnog vremena za odgovor i ocjena drugih korisnika.
        </P>
        <GreyBlock id="4" className="sections">
          <H2>Zašto Rentlio?</H2>
          <P>
            Rentlio je cloud-based hotelski Property Management sustav, Channel Manager i Web Booking Engine sa
            sjedištem u Zadru, u Hrvatskoj. Trenutno, s više od 1000 objekata diljem svijeta, Rentlio je jedan od
            najkvalitetnijih pružatelja hotelske tehnologije u turizmu i hotelijerstvu. Mi u Rentliju vjerujemo da
            integriranim Channel Managerom, Web Booking Engine-om i PMS-om ostvarujemo znatnu prednost u kvaliteti
            usluge u usporedbi s drugim pružateljima istih i sličnih usluga. Uz Rentlio, koristit ćete jedan cjelovit
            hotelski sustav koji obuhvaća jednu implementaciju, unaprijed integrirane temeljne funkcionalnosti i jedan
            tim korisničke podrške posvećen vama i vašem poslovanju.
          </P>
        </GreyBlock>
        <ButtonCTA onClick={(e) => handleClick(e)}>Saznajte više o Rentlio PMS-u</ButtonCTA>
        <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText>
        <SpaceBlock />
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
